@import '~assets/fonts/font-file.scss'

:root
    --primary-color: #28293b !important
    --primary-text-color: #2d2d2d
    --secondary-text-color: #fff
    --primary-font: "Oswald-Regular"
    --secondary-font: "Oswald-Bold"
    --tertiary-font: "Oswald-Light"

// font weight
$thin: 100
$light: 300
$regular: 400
$bold: 700
$black: 900

// font size 
    --primary-font-size: 32px
    --secondary-font-size: 0.5vw

.mat-typography h1
    font-size: 32px
    font-weight: 400
    font-family: var(--primary-font)
// button styles
::ng-deep .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent, .mat-icon-button i, .mat-icon-button .mat-icon
    color: ghostwhite !important
    opacity: 0 !important
    border: 1px solid rgb(223 227 249)

// container
.container
    margin: 0 auto
    width: 100%

// body
.body
    background: var(--primary-color) !important
    margin: 0
    padding: 0
    margin: 0 auto
    --webkit-font-smoothing: antialiased
    font-family: var(--primary-font) !important
    width: 100%

.section-header
    font-family: "Oswald-Regular"
    display: flex
    flex-wrap: wrap
    flex-direction: column
    position: relative
    color: var(--secondary-text-color)

img
    width: 100%
    height: auto

::ng-deep .mat-card-header .mat-card-header-text
    margin: 0 auto

::ng-deep .mat-card
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)
    display: block
    position: relative
    padding: 16px
    border-radius: 4px
    background: ghostwhite !important
    color: #000
    border: none
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px

::ng-deep button:focus
    outline: none !important
    // background-color: transparent!important

::ng-deep .mat-drawer-container
    background: ghostwhite !important

::ng-deep .mat-drawer-content
    // overflow: hidden !important

::ng-deep .mat-dialog-container
    background-color: ghostwhite !important
    color: rgb(16 26 69) !important

::ng-deep .mat-form-field
    color: rgb(16 26 69)
::ng-deep .mat-form-field-label
    color: rgb(16 26 69)
    
// @media screen and (min-width: 768px) 
